import lozad from "lozad";
import axios from "axios";
// import Swiper JS
import Swiper, {Pagination} from 'swiper';
// import Swiper styles
import 'swiper/css';

const arrowTop = document.querySelector(".js-top")

const initLozad = () => {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
}

const initSliderOnTablet = () => {
    new Swiper(".js-portfolio-slider", {
        slidesPerView: 'auto',
        modules: [Pagination],
        pagination: {
            el: ".swiper-pagination",
        }
    });
}

const initAnimationsDoBlock = () => {
    // создаем наблюдатель
    const animationObserver = new IntersectionObserver((entries, observer) => {
        // для каждой записи-целевого элемента
        entries.forEach(entry => {
            // если элемент является наблюдаемым
            const animationTarget = entry.target
            animationTarget.style.transform = `scale(${entry.intersectionRatio})`
            if (entry.intersectionRatio === 1) {
                animationTarget.closest(".do__item").querySelector(".do__item-right").classList.add("active")
                // прекращаем наблюдение
                observer.unobserve(animationTarget)
            }
        })
    }, {
        root: null,
        rootMargin: "0px 0px -200px 0px",
        threshold: buildThresholdList()
    })

    // с помощью цикла следим за всеми img на странице
    const animationContainer = document.querySelectorAll('.js-animation')

    animationContainer.forEach(i => {
        animationObserver.observe(i)
    })
}

const setPositionMainBlock = () => {
    if (window.screen.width > 1024) {
        if (window.scrollY > document.querySelector("#solutions").clientHeight) {
            document.getElementById("main").style.position = "static"
        } else {
            document.getElementById("main").style.position = "sticky"
        }
    }
}

const initHandlerBurger = () => {
    document.querySelector(".js-burger").addEventListener("click", (e) => {
        e.currentTarget.classList.toggle("active")
        document.querySelector(".header__nav").classList.toggle("active")
        document.getElementById("header").classList.toggle("active")
        document.body.classList.toggle("overflow")
    })
}

const initHandlerLinks = () => {
    document.querySelectorAll(".js-link").forEach(el => {
        el.addEventListener("click", (e) => {
            e.preventDefault()
            let target = e.currentTarget.querySelector("a") || e.currentTarget,
                id = target.getAttribute("href"),
                elem = document.querySelector(id)
            if (window.screen.width <= 1024) {
                document.body.classList.toggle("overflow")
                document.querySelector(".js-burger").classList.toggle("active")
                document.querySelector(".header__nav").classList.toggle("active")
                document.getElementById("header").classList.toggle("active")
            }
            elem.scrollIntoView({behavior: "smooth"})
        })
    })
}

const initFormSubmit = () => {
    document.querySelector(".js-form").addEventListener("submit", async (e) => {
        e.preventDefault();
        let form = e.target,
            formData = new FormData(form)

        formData.set("checkbox", document.querySelector("[name='checkbox']:checked")?.value || "")
        clearForm(form)
        await ajaxForm(formData, form)
    })
}

const initClearInputs = () => {
    document.querySelectorAll(".js-input").forEach(el => {
        el.addEventListener("input", (e) => {
            e.target.closest(".input-container").querySelector(".contacts__input-error").classList.remove("active")
        })
    })
}

const initHandlerArrowTop = () => {
    arrowTop.addEventListener('click', () => window.scrollTo({
        top: 0,
        behavior: "smooth"
    }))
}

const initScrollHandlers = () => {
    let scrollTop = 0;
    window.addEventListener('scroll', function () {
        // Показывать/скрывать кнопку "Вверх"
        if (window.scrollY < document.documentElement.clientHeight) {
            arrowTop.classList.remove("show")
        } else {
            arrowTop.classList.add("show")
        }

        setPositionMainBlock()

        // Показывать/скрывать шапку
        let hidePositionHeader = window.scrollY >= scrollTop && window.scrollY > document.getElementById("header").scrollHeight
        document.getElementById("header").classList[(hidePositionHeader) ? "add" : "remove"]("hide")
        scrollTop = window.scrollY

        // Анимация бликов в блоке "Делаем"
        let block = document.getElementById("do")
        let blockPositionTop = offset(block).top;
        if (window.scrollY >= blockPositionTop && window.scrollY < blockPositionTop + block.scrollHeight) {
            let scrolledBlock = window.scrollY - blockPositionTop
            if (window.screen.width > 1024 && window.screen.width <= 1920) {
                document.querySelector(".do__highlight-1").style.top = `${scrolledBlock / 1.5 + 280}px`;
                document.querySelector(".do__highlight-2").style.top = `${scrolledBlock / 1.5 + 502}px`;
            } else if (window.screen.width > 1920) {
                console.log(scrolledBlock)
                document.querySelector(".do__highlight-1").style.top = `${(scrolledBlock / 1.5 + 280) / window.screen.width * 100}vw`;
                document.querySelector(".do__highlight-2").style.top = `${(scrolledBlock / 1.5 + 502) / window.screen.width * 100}vw`;
            }
        }
    });
}

function buildThresholdList() {
    let thresholds = [];
    let numSteps = 20;

    for (let i = 1.0; i <= numSteps; i++) {
        let ratio = i / numSteps;
        thresholds.push(ratio);
    }

    return thresholds;
}

function offset(el) {
    let rect = el.getBoundingClientRect(),
        scrollLeft = window.scrollX || document.documentElement.scrollLeft,
        scrollTop = window.scrollY || document.documentElement.scrollTop;
    return {top: rect.top + scrollTop, left: rect.left + scrollLeft}
}

function clearForm(form) {
    let arrNames = ["name", "email", "phone", "message"];
    arrNames.forEach(el => {
        form.querySelector(`[name="${el}"]`).value = ""
    })
    form.querySelector(`[name="checkbox"]`).checked = false
}

async function ajaxForm(formData, form) {
    try {
        let response = await axios({
            method: "post",
            url: "/ajax.php",
            data: formData,
            headers: {"Content-Type": "multipart/form-data"},
        })
        if (response.status === 200) {
            let noticeSuccessBlock = document.getElementById("notice-success");
            noticeSuccessBlock.classList.add("active")
            setTimeout(function () {
                noticeSuccessBlock.classList.remove("active")
            }, 3000)
        }
    } catch (error) {
        if (error?.response?.status === 422 && error?.response?.data) {
            for (let key in error.response.data) {
                let elem = form.querySelector(`input[name="${key}"]`).closest(".input-container").querySelector(".contacts__input-error")
                elem.innerText = error.response.data[key]
                elem.classList.add("active")
            }
            return;
        }
        throw error
    }
}

const setHeightMobileMenu = () => {
    setTimeout(() => {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, 500)
}

document.addEventListener("DOMContentLoaded", () => {
    setHeightMobileMenu();
    setPositionMainBlock();
    initLozad();
    initSliderOnTablet();
    initAnimationsDoBlock();
    initHandlerBurger();
    initHandlerLinks();
    initFormSubmit();
    initClearInputs();
    initHandlerArrowTop();
    initScrollHandlers();
})




